import { getUser } from "@/API/auth";

export default {
    namespaced: true,
    state: () => ({
        isLogin: false,
        token: null,
        user: {},
        userStats: {}
    }),
    getters: {
        token(state) {
            return state.token;
        },
        user(state) {
            return state.user;
        },
        userStats(state) {
            return state.userStats;
        },
        isProfileCompleted(state) {
            const userInfo = state.user;

            const infoToBeCompleted = [
                {
                    label: "first_name",
                    value: userInfo.first_name
                },
                {
                    label: "last_name",
                    value: userInfo.last_name
                },
                {
                    label: "birthday",
                    value: userInfo.birthday
                },
                {
                    label: "mobile",
                    value: userInfo.mobile
                },
                {
                    label: "Specialty_id",
                    value: userInfo.Specialty_id
                },
                {
                    label: "sub_Specialty",
                    value: userInfo.sub_Specialty
                },
                {
                    label: "gender",
                    value: userInfo.gender
                },
                {
                    label: "country_id",
                    value: userInfo.country_id
                },
                {
                    label: "city_id",
                    value: userInfo.city_id
                }
            ]

            let count = 0
            let completedPercentage = 0

            infoToBeCompleted.map((info) => {
                if (info.value) count = count + 1
                completedPercentage = parseInt(((count / 9) * 100).toFixed())
            })

            return completedPercentage > 50
        }
    },
    mutations: {
        // update user info
        UPDATEAUTHINFO(state, authData) {
            if (authData?.token)
            {
                state.isLogin = true;
                state.token = authData?.token;
                state.user = authData;
                state.userStats = {
                    userID: authData.id,
                    CME: authData.CME,
                    cme_accredited: authData.cme_accredited,
                    total_accredited: authData.total_accredited,
                    modules: authData["modules‏"],
                }
                
                if (!localStorage.getItem('DLPToken'))
                {
                    localStorage.setItem('DLPToken', authData?.token);
                    localStorage.setItem('DLPUser', JSON.stringify(authData));
                }

                return;
            }

            if (authData == 'update') {
                const user = JSON.parse(localStorage.getItem('DLPUser'));
                const token = localStorage.getItem('DLPToken');

                state.isLogin = true;
                state.token = token;
                state.user = user;
                state.userStats = {
                    userID: user.id,
                    CME: user.CME,
                    cme_accredited: user.cme_accredited,
                    total_accredited: user.total_accredited,
                    modules: user["modules‏"],
                }

                getUser().then(res => {
                    if (res?.status == 200) state.user = res.data?.data;
                });

                return;
            }

            state.isLogin = false;
            state.token = null;
            state.user = {};
            state.userStats = {}
            localStorage.removeItem('DLPToken');
            localStorage.removeItem('DLPUser');
        },
        UPDATE_USER_INFO(state, user) {
            if (!user) return;
            
            state.user = user;
            localStorage.setItem('DLPUser', JSON.stringify(user));
        },

    },
    actions: {
        logoutUser({ commit }) {
            commit('UPDATEAUTHINFO', {});
        }
    },
}