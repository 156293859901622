<template>
    <div>
        <div class="head text-center mb-5">
            <h2 class="font-weight-600 text-uppercase">{{ $t('partners') }}</h2>
            <p class="subhead mx-auto text-color">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots
            </p>
        </div>
        <carousel
            :rtl="isRTL"
            :perPage="partnersPerPage"
            :autoplayTimeout="7000"
            :loop="true"
            :paginationEnabled="false"
            :autoplay="true"
            :navigationEnabled="partnersArrows"
            :navigationNextLabel="`<img width='70' src='${img}'>`"
            :navigationPrevLabel="`<img width='70' src='${img2}'>`"
        >
            <slide v-for="(partner, index) in partners" :key="index">
                <div
                    class="
                        partner-slide
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                    "
                >
                    <img
                        :src="partner.image_logo"
                        class="img-circle mb-3"
                        width="90"
                        height="90"
                    />
                    <h6 class="company-name">
                        {{ partner.name }}
                    </h6>
                </div>
            </slide>
        </carousel>
    </div>
</template>

<script>
import { getPartnersForFilter } from "@/API/utilites";
export default {
    name: "Partners",
    data() {
        return {
            partners: [],
            isRTL: this.$store.getters['layout/direction'] == 'rtl',
            partnersPerPage: 5,
            partnersArrows: true,
            img: require("@/assets/imgs/arrow-right.png"),
            img2: require("@/assets/imgs/arrow-left.png"),
        };
    },
    methods: {
        makeSliderResponsive() {
            if (window.innerWidth < 1200) this.partnersPerPage = 4;
            if (window.innerWidth < 991) this.partnersPerPage = 3;

            if (window.innerWidth < 767) this.partnersPerPage = 2;

            if (window.innerWidth < 500) {
                this.partnersPerPage = 1;
                this.partnersArrows = false;
            }
        },
        partnersData() {
            getPartnersForFilter().then(res => {
                if (res.status == 200) {
                    // console.log(res.data);
                    this.partners = res.data?.data?.partner;
                }
            });
        }
    },
    mounted() {
        this.makeSliderResponsive();
        this.partnersData();
    },
};
</script>

<style lang="scss">
    .img-circle {
        object-fit: cover;
    }
</style>