<template>
    <div class="home">
        <!-- hero banner -->
        <div class="hero-banner">
            <div v-if="sliderLoading" class="loading-sider container container-xl">
                <b-skeleton animation="wave" height="100%" width="100%" 
                style="border-radius: 0 !important"></b-skeleton>
            </div>
            <div v-else class="container container-xl">
                <!-- :paginationEnabled="false" -->
                <carousel
                    :rtl="isRTL"
                    :perPage="1"
                    :autoplayTimeout="7000"
                    :loop="true"
                    :autoplay="true"
                    :paginationEnabled="false"
                    :navigationEnabled="true"
                    :navigationNextLabel="`<img width='70' src='${img}'>`"
                    :navigationPrevLabel="`<img width='70' src='${img2}'>`"
                >
                    <slide v-for="(slide, index) in slider" :key="index">
                        <a :href="slide.link" :title="slide.link" 
                        style="width: 100%; height: 100%;">
                            <img
                                :src="slide.image"
                                class="img-fluid"
                            />
                        </a>
                        <!-- <div class="join-us">
                            <h2 class="join-title text-brand font-weight-600">
                                Join us
                            </h2>
                            <p>
                                and follow the constant renewal of your business
                            </p>
                            <router-link to="/" class="btn"
                                >Join us</router-link
                            >
                        </div> -->
                    </slide>
                </carousel>
            </div>
        </div>

        <!-- learn now -->
        <div class="learn-now py-5 mb-3">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <h4 class="text-uppercase font-weight-600 mb-3">
                            LEARN HOW
                        </h4>
                        <p class="text-gray">
                            The standard Lorem Ipsum passage, used since the
                            1500s "Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim
                            veniam, quis nostrud exercitation ullamco laboris
                            nisi ut aliquip ex ea commodo consequat. Duis aute
                            irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur
                            sint occaecat cupidatat non proident, sunt in culpa
                            qui officia deserunt mollit anim id est laborum."
                        </p>
                        <p class="mb-0 text-gray">
                            "Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque laudantium, totam
                            rem aperiam, eaque ipsa quae ab illo inventore
                            veritatis et quasi architecto beatae vitae dicta
                            sunt explicabo. Nemo enim ipsam voluptatem quia
                            voluptas sit aspernatur aut odit aut fugit, sed quia
                            consequuntur magni dolores eos qui ratione
                            voluptatem sequi nesciunt. Neque porro quisquam est,
                            qui dolorem ipsum quia dolor sit amet, consectetur,
                            adipisci velit.
                        </p>
                    </div>
                    <div class="col-lg-6 d-flex justify-items-center">
                        <img
                            src="@/assets/imgs/learn-now.png"
                            alt="LEARN HOW"
                            class="learn-vector mw-100"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- partner video -->
        <div v-if="mostVisitVideos.length" class="partner-video py-5 mb-4">
            <div class="container">
                <div class="head text-center mb-5">
                    <h2 class="font-weight-600 text-uppercase">Therapeutic Area</h2>
                </div>
                <div class="row justify-content-center pt-3 pb-lg-4">
                    <div
                        v-for="(video, index) in mostVisitVideos"
                        :key="index"
                        class="col-sm-6 col-lg-4 mb-5"
                    >
                        <div class="p-card">
                            <vimeo-player 
                            class="vimeo-player"
                            :options="{ 
                                responsive: true,
                                title: false,
                                byline: false,
                                color: '#007EC6' }"
                            :player-height="200"
                            :player-width="640"
                            :player-maxheight="200"
                            :video-id='videoID(video.vedio_link)'></vimeo-player>
                            <div class="video-title">{{ video.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- partners section -->
        <div class="partners-section mb-5">
            <div class="container">
                <Partners />
            </div>
        </div>

        <!-- become a partner section -->
        <div class="mb-4 pt-5">
            <div class="container">
                <BecomePartner />
            </div>
        </div>

        <!-- articles -->
        <div v-if="mostVisitArticles.length" class="articles py-5 mb-4">
            <div class="container">
                <div class="head text-center mb-5">
                    <h2 class="font-weight-600 text-uppercase">ARTICALES</h2>
                    <p class="subhead mx-auto text-color">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots
                    </p>
                </div>
                <div class="row justify-content-center pt-3">
                    <div
                        v-for="(item, index) in mostVisitArticles"
                        :key="index"
                        class="col-sm-6 col-lg-4 col-xl-3 mb-4"
                    >
                        <div class="article-card">
                            <img
                                class="article-card-img"
                                :src="item.artical_image"
                                alt="article title"
                            />
                            <div class="article-card-body">
                                <h5 class="text-capitalize">{{ item.title }}</h5>
                                <p class="article-card-content text-gray">
                                    {{ textSummary(item.body, 120) }}
                                </p>
                                <button class="btn btn-brand btn-block">
                                    Details
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- DLP Featuers -->
        <div class="dlp-features py-5 mb-5">
            <div class="container">
                <div class="head text-center mb-5">
                    <h2 class="font-weight-600 text-uppercase">
                        DLP SYSTEM FEATURES
                    </h2>
                    <p class="subhead mx-auto text-color">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots
                    </p>
                </div>
                <div class="row pt-3">
                    <div
                        v-for="item in 8"
                        :key="item"
                        class="col-sm-6 col-lg-4 col-xl-3 mb-5"
                    >
                        <div class="d-flex">
                            <div>
                                <span
                                    class="
                                        icon
                                        d-flex
                                        justify-content-center
                                        align-items-center
                                        mt-1
                                        bg-brand
                                        rounded-circle
                                    "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="35.496"
                                        height="27.025"
                                        viewBox="0 0 35.496 27.025"
                                    >
                                        <path
                                            d="M16.394,29.778,7.924,21.308,5.1,24.131,16.394,35.425l24.2-24.2L37.773,8.4Z"
                                            transform="translate(-5.1 -8.4)"
                                            fill="#fff"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div>
                                <h5 class="mb-1">Articles title</h5>
                                <p class="mb-0 text-gray">
                                    Contrary to popular belief, Lorem Ipsum is
                                    not simply random text
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Join us section -->
        <div class="mb-5 pt-5">
            <div class="container">
                <div
                    class="
                        become-partner
                        join-us
                        d-flex
                        flex-column flex-lg-row
                        align-items-center
                    "
                >
                    <div class="img d-flex justify-content-center">
                        <img
                            src="@/assets/imgs/join-us.png"
                            alt="become a partner"
                        />
                    </div>
                    <div class="content">
                        <h4 class="text-uppercase font-weight-600 mb-2 mb-sm-4">
                            JOIN US
                        </h4>
                        <p class="text-gray mb-sm-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.
                        </p>
                        <a
                            href="javascript:;"
                            class="
                                btn btn-brand
                                d-block d-sm-inline-block
                                px-sm-5
                            "
                            to="/contact-us"
                        >
                            Join
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- FAQ -->
        <div class="faq pt-4">
            <div class="container container-xl pb-5 mb-md-4">
                <div class="head text-center mb-5">
                    <h2 class="font-weight-600 text-uppercase">
                        FREQUENTLY ASKED QUESTIONS
                    </h2>
                </div>
                <FAQ />
            </div>
        </div>

        <!-- newsletter -->
        <div class="newsletter py-5">
            <div class="container container-xl">
                <Subscribe />
            </div>
        </div>
        <TopFooterAds />
    </div>
</template>

<script>
import TopFooterAds from "@/components/TopFooterAds";
import FAQ from "@/components/FAQ";
import Partners from "@/components/Partners";
import BecomePartner from "@/components/BecomePartner";
import Subscribe from "@/components/Subscribe";

import { getSlider } from "@/API/slider";
import { getMostVisitedVideos, getMostVisitedArticles } from "@/API/modules";
import textSummary from "@/mixins/textSummary";

import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
    name: "Home",
    mixins: [textSummary],
    components: {
        TopFooterAds,
        FAQ,
        Partners,
        BecomePartner,
        Subscribe,
        vimeoPlayer: vueVimeoPlayer
    },
    data() {
        return {
            isRTL: this.$store.getters['layout/direction'] == 'rtl',
            img: require("@/assets/imgs/arrow-right.png"),
            img2: require("@/assets/imgs/arrow-left.png"),
            slider: [],
            sliderLoading: true,
            mostVisitVideos: [],
            mostVisitArticles: []
        };
    },
    methods: {
        videoID(url) {
            if (url) {
                const urlArry = url.split('/')
                // console.log(urlArry[urlArry.length - 1]);
                return urlArry[urlArry.length - 1]
            }
                
            return 0;
        },
        homeSlider() {
            getSlider().then(res => {
                if (res.status == 200) {
                    // console.log(slider);
                    const slider = res.data?.data?.userSlider;
                    this.slider = slider;
                    this.sliderLoading = false;
                }
            });
        },
        mostVisitedVideos() {
            getMostVisitedVideos().then(res => {
                if (res.status == 200) {
                    const data = res.data?.data;
                    this.mostVisitVideos = data.map(d => d.matrial);
                    // console.log('visited video', this.mostVisitVideos);
                }
            });
        },
        mostVisitArticle() {
            getMostVisitedArticles().then(res => {
                if (res.status == 200) {
                    const data = res.data?.data;
                    // console.log('visited articles', data);
                    this.mostVisitArticles = data?.matrial;
                }
            });
        }
    },
    mounted() {
        this.homeSlider();
        this.mostVisitedVideos()
        this.mostVisitArticle()
    }
};
</script>
