<template>
    <div v-if="isLogin">
        <div :class="[isSearch ? 'show' : '']" class="mobile-overlay"></div>
        <div :class="[isSearch ? 'show' : '']" class="search-mobile">
            <form
                @submit.prevent="searchSomething()"
                class="search-box d-flex align-items-center w-100"
            >
                <input
                    type="search"
                    class="form-control"
                    :placeholder="`${$t('search')}...`"
                />
                <button type="submit" class="btn">{{ $t('search') }}</button>
            </form>
            <div class="search-body">
                <div v-if="searching" class="searching">
                    <b-spinner variant="light"></b-spinner>
                </div>
                <ul v-else>
                    <!-- <li v-for="item in 5" :key="item">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.
                        </p>
                    </li> -->
                </ul>
            </div>
        </div>
        <div :class="[isMenu ? 'show' : '']" class="mobile-overlay"></div>
        <ul
            @click="isMenu = false; isNotificationsMenu = false"
            :class="[isMenu ? 'show' : '']"
            class="mobile-links"
        >
            <li>
                <router-link :to="`${isLogin ? '/dashboard' : '/'}`">
                    {{ $t('home') }}
                </router-link>
            </li>
            <li>
                <router-link to="/about"> {{ $t('about') }} </router-link>
            </li>
            <li>
                <router-link to="/partners"> {{ $t('partners') }} </router-link>
            </li>
            <li>
                <router-link to="/events"> {{ $t('events') }} </router-link>
            </li>
            <li>
                <router-link to="/contact-us"> {{ $t('contact') }} </router-link>
            </li>
        </ul>

        <div @click="isNotificationsMenu" :class="[isNotificationsMenu ? 'show' : '']" class="mobile-overlay"></div>
        <ul :class="[isNotificationsMenu ? 'show' : '']" class="notifications-menu">
            <template v-if="notifications.length">
                <li v-for="(notification, index) in notifications" :key="index" class="notification-item bg-white d-flex py-2 mb-1" :class="{unread : notification.unread}" style="border-radius: 10px">
                    <div class="mx-2">
                        <!-- :src="notification.icon" -->
                        <img v-if="notification.icon" class="notification-icon" :src="notification.icon">
                        <img v-else class="notification-icon" src="@/assets/imgs/avatar.png">
                    </div>
                    <div class="mx-2 w-100">
                        <h6 class="title mb-0 whitespace-normal">{{ notification.title }}</h6>
                        <small class="time d-block mb-1">
                            {{ $moment(notification.created_at).fromNow() }}
                        </small>
                        <small class="d-block whitespace-normal">
                            {{ notification.body }}
                        </small>
                    </div>
                </li>
            </template>
            <template v-else>
                <li class="bg-white dropdown-item notification-item text-center px-3">
                    <p class="mb-0 py-3">{{ $t('noNotifications') }}</p>
                </li>
            </template>
        </ul>
        <div class="mobile-menu">
            <button
                @click="isMenu = !isMenu; 
                isNotificationsMenu = false;
                isSearch = false"
                :class="[isMenu ? 'show' : '']"
                class="btn p-0 menu-btn"
            >
                <span class="close-icon">
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#007EC6"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-x"
                        >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                </span>
                <span class="text">
                    <img
                        v-if="user.images"
                        class="img-circle"
                        width="65"
                        height="65"
                        :src="user.images"
                        :alt="user.first_name || user.email"
                    />
                    <img
                        v-else
                        class="img-circle"
                        width="65"
                        height="65"
                        src="@/assets/imgs/avatar.png"
                        alt="avatar"
                    />
                </span>
            </button>
            <div class="menu-controls">
                <div class="cart-status">
                    <span @click="isMenu = false">
                        <router-link
                            to="/my-account"
                            class="btn cart-mobile-btn"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                viewBox="0 0 22 22"
                                fill="#fff"
                                opacity="0.9"
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path
                                    d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                                />
                            </svg>
                        </router-link>
                    </span>
                    <span @click="isMenu = false; isSearch = false">
                        <button @click="showNotifications" class="btn cart-mobile-btn pb-0">
                            <span v-if="unreadCount" class="count">{{ unreadCount }}</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="25"
                                viewBox="0 0 19.321 24.165"
                            >
                                <g transform="translate(-6.761 -3.93)">
                                    <path
                                        d="M17.515,31.537c1.878,0,2.905-1.329,2.905-3.2H14.6C14.6,30.208,15.631,31.537,17.515,31.537Z"
                                        transform="translate(-1.106 -3.442)"
                                        fill="#fff"
                                        opacity="0.8"
                                    />
                                    <path
                                        d="M25.837,21.826c-.93-1.226-2.76-1.945-2.76-7.435,0-5.635-2.488-7.9-4.808-8.443-.217-.054-.374-.127-.374-.356V5.416a1.474,1.474,0,1,0-2.947,0v.175c0,.223-.157.3-.374.356-2.325.55-4.808,2.808-4.808,8.443,0,5.49-1.83,6.2-2.76,7.435a1.2,1.2,0,0,0,.96,1.921H24.882A1.2,1.2,0,0,0,25.837,21.826Z"
                                        transform="translate(0 0)"
                                        fill="#fff"
                                        opacity="0.9"
                                    />
                                </g>
                            </svg>
                        </button>
                    </span>
                </div>
                <div class="settings">
                    <span @click="isMenu = false">
                        <router-link to="/my-account/favorites" class="btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25.705"
                                height="22.492"
                                viewBox="0 0 25.705 22.492"
                            >
                                <path
                                    d="M23.209,3.786a6.865,6.865,0,0,0-9.368.683l-.989,1.019-.989-1.019a6.865,6.865,0,0,0-9.368-.683A7.209,7.209,0,0,0,2,14.223l9.715,10.031a1.574,1.574,0,0,0,2.274,0L23.7,14.223a7.2,7.2,0,0,0-.492-10.437Z"
                                    transform="translate(0.001 -2.248)"
                                    fill="#007ec6"
                                />
                            </svg>
                        </router-link>
                    </span>
                    <span
                        @click="
                            isMenu = false;
                            isNotificationsMenu = false;
                            isSearch = !isSearch;
                        "
                    >
                        <button class="btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 42.657 42.816"
                            >
                                <g transform="translate(-107.973 -180.092)">
                                    <g>
                                        <path
                                            d="M149.188,213.833l-7.047-7.048a19.442,19.442,0,0,0,2.135-8.542A18.152,18.152,0,1,0,126.124,216.4a19.442,19.442,0,0,0,8.542-2.135l7.048,7.047a5.161,5.161,0,0,0,7.474,0A5.565,5.565,0,0,0,149.188,213.833Zm-23.064-1.709a13.88,13.88,0,1,1,13.881-13.881A13.853,13.853,0,0,1,126.124,212.124Z"
                                            fill="#007ec6"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileMenu",
    data() {
        return {
            isMenu: false,
            isSearch: false,
            searching: false,
            isNotificationsMenu: false
        };
    },
    computed: {
        user() {
            return this.$store.state.auth?.user;
        },
        isLogin() {
            return this.$store.state.auth?.isLogin || false;
        },
        notifications() {
            return this.$store.getters['firebase/notifications'];
        },
        unreadCount() {
            return this.$store.getters['firebase/unreadCount'];
        },
    },
    methods: {
        searchSomething() {
            this.searching = true;
        },
        showNotifications() {
            this.isMenu = false
            this.isNotificationsMenu = !this.isNotificationsMenu
        },
    },
};
</script>

<style lang="scss" scoped>
$main_color: #007ec6;
$dark_color: #231f20;
$purple_color: #823893;
$green: #c5dd85;
$white: #fff;
$hover: #f12f48;
$bg: #f7fcfe;
$active: #fafafa;
$text: #495057;
$overlay: #823893;
$gray: rgba(0, 0, 0, 0.48);

.mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 126, 198, 0.8);
    visibility: hidden;
    opacity: 0;
    z-index: 997;
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.show {
        visibility: visible;
        opacity: 1;
    }
}

.mobile-links {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
    padding: 0.5rem 0.5rem 120px 0.5rem;
    visibility: hidden;
    opacity: 0;

    li {
        display: inline-block;
        margin-right: auto;
        margin-left: auto;
        &:not(:last-of-type) {
            margin-bottom: 1.5rem;
        }
    }

    a {
        display: inline-block;
        background-color: #fff;
        padding: 0.5rem 2rem;
        border-radius: 20px;
        border: 1px solid transparent;
        visibility: hidden;
        opacity: 0;
        transform: translateY(10px);
        transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

        &.router-link-exact-active {
            background-color: #007ec6;
            border-color: #fff;
            color: #fff !important;
        }
    }

    &.show {
        visibility: visible;
        opacity: 1;

        a {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            &:first-child {
                transition-delay: 0.2;
            }
            &:htn-child(2) {
                transition-delay: 0.7;
            }
            &:htn-child(3) {
                transition-delay: 1.4;
            }
            &:htn-child(4) {
                transition-delay: 2.1;
            }
        }
    }
}

.search-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 0.5rem 0.5rem 120px 0.5rem;
    visibility: hidden;
    opacity: 0;

    .search-box {
        .form-control {
            height: 40px;
            border-radius: 20px 0 0 20px;
            border: 0;
            &::placeholder {
                font-weight: normal;
            }
        }
        .btn {
            height: 40px;
            background-color: #fff;
            color: #007ec6 !important;
            border-radius: 0 20px 20px 0;
        }
    }

    .search-body {
        position: relative;
        min-height: 25vh;
        padding: 1rem 10px;
        overflow-y: auto;

        .searching {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        p {
            color: #fff;
        }
    }

    &.show {
        visibility: visible;
        opacity: 1;
    }
}

.notifications-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 0.5rem 0.5rem 120px 0.5rem;
    visibility: hidden;
    opacity: 0;
    overflow: auto;

    &.show {
        visibility: visible;
        opacity: 1;
    }

    .notification-item {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        font-size: 0.9rem;

        .notification-icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
        }

        .title {
            font-size: 0.9rem;
            padding-top: 0.1rem;
        }

        .time {
            color: $gray;  
        }

        p {
            white-space: break-spaces;
        }

        &.unread,
        &:hover {
            background-color: rgba(0, 126, 198, 0.1);
        }
    }
}

.mobile-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    direction: rtl !important;

    @media screen and (min-width: 581px) {
        display: none;
    }
    .menu-btn {
        position: relative;
        z-index: 4;
        margin-bottom: -2rem;
        width: 70px;
        height: 70px;
        padding: 0;
        min-width: 0;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
            0px 6px 10px 0px rgba(0, 0, 0, 0.14),
            0px 1px 18px 0px rgba(0, 0, 0, 0.12);
        border-radius: 50%;
        color: $white;
        background-color: #f9f9f9;
        .close-icon,
        .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .close-icon {
            visibility: hidden;
            z-index: 2;
            & > div {
                transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }
        .text {
            transition: 0.4s ease-out;
            z-index: 1;
            overflow: hidden;
        }

        &.show {
            .close-icon {
                visibility: visible;
                & > div {
                    transform: rotate(180deg);
                }
            }
            .text {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    .menu-controls {
        position: relative;
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background: transparent;
        overflow: hidden;
        &::before {
            content: "";
            position: absolute;
            top: -36px;
            width: 72px;
            height: 72px;
            border-radius: 50%;
            z-index: -1;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            box-shadow: 240px 90px 0 240px #007ec6;
            // box-shadow: 60px 90px 0 240px #007EC6;
        }
    }
    .cart-status {
        position: relative;
        height: 50px;
        overflow: hidden;
        width: 50%;
        height: 100%;
        padding: 0.75rem;
        padding-left: 2rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: transparent;
        color: $white;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.12);
        .cart-mobile-btn {
            position: relative;
            .count {
                position: absolute;
                top: -2px;
                right: 0;
                width: 25px;
                height: 25px;
                z-index: 33;
                color: $main_color;
                background-color: $white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.8rem;
            }
        }
        @media screen and (max-width: 415px) {
            justify-content: space-between;
            padding-right: 0.5rem;
        }
    }
    .settings {
        position: relative;
        overflow: hidden;
        width: 50%;
        height: 60px;
        padding: 0.75rem 1.5rem 0.75rem 0.75rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: transparent;
        color: $main_color;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.12);
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        &::before {
            content: "";
            position: absolute;
            top: 0;
            height: 36px;
            width: 36px;
            box-shadow: 0 0 0 500em #fff, inset 0 0 8px -5px;
            border-radius: 0 0 3.5em 0;
            right: 0;
            transform: rotate(90deg);
            z-index: -1;
        }

        @media screen and (max-width: 415px) {
            padding-left: 0;
            button:first-child {
                margin-left: 0;
            }
            button:last-child {
                margin-left: -1rem;
            }
        }
    }
}

body[dir="rtl"] {
    .search-mobile {
        .search-box {
            .form-control {
                border-radius: 0 20px 20px 0;
            }
            .btn {
                border-radius: 20px 0 0 20px;
            }
        }
    }
}
</style>