import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from '@/lang';

// import firebaseMessaging from './firebase'

Vue.config.productionTip = false;

// bootstrap
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

// vue select
import vSelect from 'vue-select';
import "vue-select/src/scss/vue-select.scss";
Vue.component('v-select', vSelect);

// vue carousel
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

// vue notifications
import Notifications from 'vue-notification';
Vue.use(Notifications);

// V calender
import VCalendar from 'v-calendar';
Vue.use(VCalendar);

// moment js
Vue.use(require('vue-moment'));

// PerfectScrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
Vue.use(PerfectScrollbar);

import VueProgressiveImage from 'vue-progressive-image';
Vue.use(VueProgressiveImage);

// if (location.protocol == 'https:') {
//   try {
//     Vue.prototype.$messaging = firebaseMessaging
    
//     Vue.prototype.$messaging.onMessage((payload) => {
//       console.log('Message received from main. ', payload);
      
//     });
//   } catch (error) {
//     console.log(error.message);
//   }
// }

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
