import { API, logError } from "./index";

const getModulesByType = async (type) => {
    let URL = '/featured-modules';

    if (type == 'recommend') URL = '/recommend-module';
    if (type == 'visit') URL = '/visit-modules';
    if (type == 'new') URL = '/new-module';

    try {
        return await API().get(URL);
    } catch (error) {
        return logError(error);
    }
};

const singleModule = async (materialCode) => {

    try {
        return await API().get(`/module?material_code=${materialCode}`);
    } catch (error) {
        return logError(error);
    }
};

const advertisementModule = async (moduleID) => {

    try {
        return await API().get(`/advertisement-module?module_id=${moduleID}`);
    } catch (error) {
        return logError(error);
    }
};

const sendProgressPercentage = async (data) => {

    try {
        return await API().post(`/user-module`, data);
    } catch (error) {
        return logError(error);
    }
};

const materialVisited = async (data) => {

    try {
        return await API().post(`/material-visit`, data);
    } catch (error) {
        return logError(error);
    }
};

const getMostVisitedVideos = async () => {

    try {
        return await API().get(`/material-visit-video`);
    } catch (error) {
        return logError(error);
    }
};

const getMostVisitedArticles = async () => {

    try {
        return await API().get(`/material-visit-artical`);
    } catch (error) {
        return logError(error);
    }
};

const buyModule = async (data) => {
    try {
        return await API().post(`/own-Module`, data);
    } catch (error) {
        return logError(error);
    }
}


export {
    getModulesByType,
    singleModule,
    advertisementModule,
    sendProgressPercentage,
    materialVisited,
    getMostVisitedVideos,
    getMostVisitedArticles,
    buyModule
};