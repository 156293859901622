<template>
    <div :class="[isLogin ? 'loggedin' : '']" class="top-footer pt-5 pb-4">
        <div class="container">
            <div class="row align-items-center">
                <div
                    :class="[
                        isFooterPartner
                            ? 'col-md-6 order-2 order-md-1'
                            : 'col-sm-6 order-4 order-xl-1 pt-4',
                    ]"
                    class="col-lg-4 col-xl-3 mb-3"
                >
                    <div>
                        <router-link
                            :to="`${isLogin ? '/dashboard' : '/'}`"
                            class="mb-2 d-block"
                        >
                            <img src="@/assets/imgs/logo.svg" alt="DLP" />
                        </router-link>
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                    </p>
                    <ul
                        class="
                            socials
                            d-flex
                            align-items-center
                            justify-content-center justify-content-sm-start
                        "
                    >
                        <!-- facebook -->
                        <li>
                            <a href="#" target="_blank">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                >
                                    <rect
                                        id="Area_ICON:feather_facebook_SIZE:MEDIUM_STATE:DEFAULT_STYLE:STYLE3_"
                                        data-name="Area [ICON:feather/facebook][SIZE:MEDIUM][STATE:DEFAULT][STYLE:STYLE3]"
                                        width="20"
                                        height="20"
                                        fill="rgba(253,73,198,0.35)"
                                        class="fill"
                                        opacity="0"
                                    />
                                    <g
                                        id="Icon"
                                        transform="translate(5.833 1.667)"
                                    >
                                        <path
                                            id="Path"
                                            d="M15,1.667H12.5A4.167,4.167,0,0,0,8.333,5.833v2.5h-2.5v3.334h2.5v6.666h3.334V11.667h2.5L15,8.333H11.667v-2.5A.833.833,0,0,1,12.5,5H15Z"
                                            transform="translate(-5.833 -1.667)"
                                            fill="none"
                                            stroke="#3f3f3f"
                                            class="stroke"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.667"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <!-- twitter -->
                        <li>
                            <a href="#" target="_blank">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20.001"
                                    height="20"
                                    viewBox="0 0 20.001 20"
                                >
                                    <g transform="translate(0)">
                                        <rect
                                            id="Area_ICON:feather_twitter_SIZE:MEDIUM_STATE:DEFAULT_STYLE:STYLE3_"
                                            data-name="Area [ICON:feather/twitter][SIZE:MEDIUM][STATE:DEFAULT][STYLE:STYLE3]"
                                            width="20"
                                            height="20"
                                            fill="rgba(253,73,198,0.35)"
                                            class="fill"
                                            opacity="0"
                                        />
                                        <g
                                            id="Icon"
                                            transform="translate(0.833 2.491)"
                                        >
                                            <path
                                                id="Path"
                                                d="M19.167,2.5A9.094,9.094,0,0,1,16.55,3.775,3.733,3.733,0,0,0,10,6.275v.833A8.884,8.884,0,0,1,2.5,3.333s-3.333,7.5,4.167,10.834A9.7,9.7,0,0,1,.833,15.833C8.333,20,17.5,15.833,17.5,6.25a3.739,3.739,0,0,0-.067-.692A6.427,6.427,0,0,0,19.167,2.5Z"
                                                transform="translate(-0.833 -2.491)"
                                                fill="none"
                                                stroke="#3f3f3f"
                                                class="stroke"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1.667"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <!--  linked in -->
                        <li>
                            <a href="#" target="_blank">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                >
                                    <rect
                                        id="Area_ICON:feather_linkedin_SIZE:MEDIUM_STATE:DEFAULT_STYLE:STYLE3_"
                                        data-name="Area [ICON:feather/linkedin][SIZE:MEDIUM][STATE:DEFAULT][STYLE:STYLE3]"
                                        width="20"
                                        height="20"
                                        fill="rgba(253,73,198,0.35)"
                                        class="fill"
                                        opacity="0"
                                    />
                                    <g
                                        id="Icon"
                                        transform="translate(1.667 1.667)"
                                    >
                                        <path
                                            id="Path"
                                            d="M13.333,6.667a5,5,0,0,1,5,5V17.5H15V11.667a1.667,1.667,0,1,0-3.333,0V17.5H8.333V11.667A5,5,0,0,1,13.333,6.667Z"
                                            transform="translate(-1.667 -1.667)"
                                            fill="none"
                                            stroke="#3f3f3f"
                                            class="stroke"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.667"
                                        />
                                        <rect
                                            id="Rect"
                                            width="3.333"
                                            height="10"
                                            transform="translate(0 5.833)"
                                            fill="none"
                                            stroke="#3f3f3f"
                                            class="stroke"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.667"
                                        />
                                        <circle
                                            id="Path-2"
                                            data-name="Path"
                                            cx="1.667"
                                            cy="1.667"
                                            r="1.667"
                                            transform="translate(0 0)"
                                            fill="none"
                                            stroke="#3f3f3f"
                                            class="stroke"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.667"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <!--  youtube -->
                        <li>
                            <a href="#" target="_blank">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20.002"
                                    height="20"
                                    viewBox="0 0 20.002 20"
                                >
                                    <g transform="translate(0.001)">
                                        <rect
                                            id="Area_ICON:feather_youtube_SIZE:MEDIUM_STATE:DEFAULT_STYLE:STYLE3_"
                                            data-name="Area [ICON:feather/youtube][SIZE:MEDIUM][STATE:DEFAULT][STYLE:STYLE3]"
                                            width="20"
                                            height="20"
                                            fill="rgba(253,73,198,0.35)"
                                            class="fill"
                                            opacity="0"
                                        />
                                        <g transform="translate(1.667 1.667)">
                                            <path
                                                id="Path"
                                                d="M18.783,5.35a2.316,2.316,0,0,0-1.616-1.667A60.022,60.022,0,0,0,10,3.333a54.752,54.752,0,0,0-7.167.384A2.315,2.315,0,0,0,1.217,5.383,24.145,24.145,0,0,0,.833,9.792a24.132,24.132,0,0,0,.384,4.441,2.316,2.316,0,0,0,1.616,1.6A54.752,54.752,0,0,0,10,16.217a54.752,54.752,0,0,0,7.167-.384,2.315,2.315,0,0,0,1.616-1.666,24.131,24.131,0,0,0,.384-4.375A24.143,24.143,0,0,0,18.783,5.35Z"
                                                transform="translate(-1.667 -1.667)"
                                                fill="none"
                                                stroke="#3f3f3f"
                                                class="stroke"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1.667"
                                            />
                                            <path
                                                id="Path-2"
                                                data-name="Path"
                                                d="M8.125,12.517l4.792-2.725L8.125,7.067Z"
                                                transform="translate(-1.667 -1.667)"
                                                fill="none"
                                                stroke="#3f3f3f"
                                                class="stroke"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1.667"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <!-- phone -->
                        <li>
                            <a href="tel:+201010474512">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                >
                                    <rect
                                        id="Area_ICON:feather_phone_SIZE:MEDIUM_STATE:DEFAULT_STYLE:STYLE3_"
                                        data-name="Area [ICON:feather/phone][SIZE:MEDIUM][STATE:DEFAULT][STYLE:STYLE3]"
                                        width="20"
                                        height="20"
                                        fill="rgba(253,73,198,0.35)"
                                        class="fill"
                                        opacity="0"
                                    />
                                    <g
                                        id="Icon"
                                        transform="translate(1.667 3.333)"
                                    >
                                        <path
                                            id="Path"
                                            d="M18.333,14.1v2.5a1.667,1.667,0,0,1-1.816,1.667,16.488,16.488,0,0,1-7.192-2.559,16.24,16.24,0,0,1-5-5A16.488,16.488,0,0,1,1.767,3.483,1.667,1.667,0,0,1,3.425,1.667h2.5A1.666,1.666,0,0,1,7.592,3.1a10.68,10.68,0,0,0,.583,2.342A1.665,1.665,0,0,1,7.8,7.2L6.742,8.258a13.319,13.319,0,0,0,5,5L12.8,12.2a1.665,1.665,0,0,1,1.758-.375,10.68,10.68,0,0,0,2.342.583A1.667,1.667,0,0,1,18.333,14.1Z"
                                            transform="translate(-1.667 -3.333)"
                                            fill="none"
                                            stroke="#3f3f3f"
                                            class="stroke"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.667"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
                <div
                    v-if="isFooterPartner"
                    class="
                        col-md-6 col-lg-8 col-xl-9
                        order-1 order-md-2
                        mb-5 mb-md-0
                    "
                >
                    <BecomePartner :isFooterPartner="isFooterPartner" />
                </div>
                <template v-if="!isFooterPartner">
                    <div
                        v-for="add in adds" :key="add.id"
                        class="
                            col-sm-6 col-lg-4 col-xl-3
                            order-1 order-xl-2
                            mb-3
                            text-center
                            ad
                        "
                    >
                        <img
                            :src="add.image"
                            class="mw-100"
                            alt="ad name"
                        />
                    </div>
                </template>
                <!-- <div
                    v-if="!isFooterPartner"
                    class="
                        col-sm-6 col-lg-4 col-xl-3
                        order-2 order-xl-3
                        mb-3
                        text-center
                        ad
                    "
                >
                    <img
                        src="@/assets/imgs/ad.png"
                        class="mw-100"
                        alt="ad name"
                    />
                </div>
                <div
                    v-if="!isFooterPartner"
                    class="
                        col-sm-6 col-lg-4 col-xl-3
                        order-3 order-xl-4
                        mb-3
                        text-center
                        ad
                    "
                >
                    <img
                        src="@/assets/imgs/ad.png"
                        class="mw-100"
                        alt="ad name"
                    />
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import BecomePartner from "@/components/BecomePartner";
import { getAdds } from "@/API/adds";
export default {
    name: "TopFooterAds",
    components: {
        BecomePartner,
    },
    data() {
        return {
            loadingAdds: true,
            adds: []
        }
    },
    computed: {
        isLogin() {
            return this.$store.state.auth?.isLogin || false;
        },
        isFooterPartner() {
            return this.$store.state.layout?.isFooterPartner || false;
        },
    },
    methods: {
        getAllAdds() {
            getAdds().then(res => {
                if (res.status == 200) {
                    const data = res.data?.data;
                    // console.log(data);
                    this.adds = data.ad;
                }

                if (res?.response?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                this.loadingAdds = false;
            });
        }
    },
    created() {
        this.getAllAdds();
    }
};
</script>
